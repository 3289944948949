import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {backendURL} from "../../utils/env";
import AnswersInputView from "./AnswersInputView";

const propTypes = {
  resultID: PropTypes.number.isRequired,
};

const defaultProps = {
};

export default class QuestionsView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      items: [],
    }
  }
  componentDidMount() {
    fetch(`${backendURL}/api/answered-results/by-result-id/${this.props.resultID}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            isLoaded: true,
            items: result.response.items,
          })
        },
        (error) => {
          console.log(error);
          },
      );
  }

  render() {
    const {
      isLoaded, items,
    } = this.state;

    if (!isLoaded) return <div>Loading...</div>
    else return (
      <React.Fragment>
        {items && items.length > 0 && items.map((item, index) => (
          <div className="test_body" key={index}>
            <div className="test_body_title">
              {item.question.title}
            </div>
            <div className="test_body_subtitle">
              Выберите один верный ответ.
            </div>
            <AnswersInputView
              questionID={item.question.id}
              selectedAnswer={item.answer}
            />
          </div>
        ))}
      </React.Fragment>
    );
  };
}

QuestionsView.propTypes = propTypes;
QuestionsView.defaultProps = defaultProps;

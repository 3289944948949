/**
 * Get random five question by User test level
 * @param {!array} questions
 * @param {!int} userLevel
 * @returns {array|null}
 */
export const getRandomFiveQuestionsByUserLevel = (questions, userLevel) => {
  const validQuestions = questions.filter(obj => {
    return obj.level === userLevel;
  });
  if (validQuestions.length >= 5) {
    const splicedArr = validQuestions.splice(0, 5);
    return shuffle(splicedArr);
  } else return null;
}

/**
 * Random array sorting
 *
 * @param {!array} array
 * @returns {array}
 */
const shuffle = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    let j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

import React, { Component } from 'react';
import {
  clearLocalStorage,
  userID,
  userIsAuth,
} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";

export default class PassedTestsPage extends Component {
  constructor() {
    super();

    this.state = {
      items: [],
    };
    this.handlerOpenNewTab = this.handlerOpenNewTab.bind(this);
  };

  handlerOpenNewTab(e)
  {
    window.location.replace('/student/available-tests');
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      fetch(`${backendURL}/api/results/by-user/${userID}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              items: result.response.items,
            });
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  render() {
    const {
      items,
    } = this.state;
    return (
      <div className="mb_student">
        <div className="container">
          <div className="test_tab">
            <ul className="test_tab_nav">
              <li>
                <button data-content="available" onClick={this.handlerOpenNewTab}>Доступные тесты</button>
              </li>
              <li>
                <button className="active" data-content="passed">Пройденные тесты</button>
              </li>
            </ul>
            <div className="test_tab_body ">
              <div className="test_content active" id="passed">
                {items.map((item, index) => (
                  <div className="test_main" key={index}>
                    <div className="test_main_title">
                      {item.test.title}
                      <a className="test_main_button_mb" href={`/student/view-result/${item.id}`}>ПОСМОТРЕТЬ РЕЗУЛЬТАТ</a>
                    </div>
                    <div className="test_main_button">
                      <a href={`/student/view-result/${item.id}`}>ПОСМОТРЕТЬ РЕЗУЛЬТАТ</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

import React, {Component} from 'react';
import PropTypes from 'prop-types';

import trashImage from '../../resources/trashcan.png';

const propTypes = {
  questionNumber: PropTypes.number,
  questionTitle: PropTypes.string,
  handleRemoveButton: PropTypes.any,
  viewPage: PropTypes.bool,
  editPage: PropTypes.bool,
  handleSelectQuestion: PropTypes.any,
  questionSelected: PropTypes.number,
  questionLevel: PropTypes.number,
};

const defaultProps = {
  questionNumber: undefined,
  questionTitle: undefined,
  handleRemoveButton: undefined,
  viewPage: false,
  editPage: false,
  handleSelectQuestion: undefined,
  handleRemoveQuestion: undefined,
  questionSelected: undefined,
  questionLevel: undefined,
};

export default class QuestionItem extends Component {
  constructor(props) {
    super(props);

    this.handleForRemoveButton = this.handleForRemoveButton.bind(this);
    this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
  }

  handleForRemoveButton(e) {
    this.props.handleRemoveButton(e.target.id);
  }

  handleSelectQuestion(e)
  {
    this.props.handleSelectQuestion(this.props);
  }

  render() {
    const {
      questionNumber,
      questionTitle,
      viewPage,
      editPage,
      questionSelected,
    } = this.props;

    return (
      <div className={`questions_item ${questionSelected === questionNumber ? 'active' : ''}`}>
        <div className="questions_item_top">
          <div id={questionNumber} onClick={viewPage || editPage ? this.handleSelectQuestion : undefined} className="questions_number">Вопрос #{questionNumber}</div>
          <div className="trashcan">
            {!viewPage && (
              <button
                onClick={this.handleForRemoveButton}
                className="remove_button"
                id={questionNumber}>
                <img id={questionNumber} src={trashImage} alt="trashcan" />
              </button>
            )}
          </div>
        </div>
        <div className="questions_item_title">
          {questionTitle}
        </div>
      </div>
    );
  }
}

QuestionItem.propTypes = propTypes;
QuestionItem.defaultProps = defaultProps;

import React, { Component } from 'react';
import {clearLocalStorage, userID, userIsAuth, userIsTeacher} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";

import styles from "./style.module.scss";

export default class StudentsResults extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      tests: [],
      results: [],
      selectedClassNumber: 6,
      selectedClassChar: "a",
      filteredResults: [],
    };

    this.handleChangeQuestion = this.handleChangeQuestion.bind(this);
    this.handleFilterButton = this.handleFilterButton.bind(this);
    this.handleChangeClassNumber = this.handleChangeClassNumber.bind(this);
    this.handleChangeClassChar = this.handleChangeClassChar.bind(this);
  }

  handlerOpenNewTab(e)
  {
    if (e.target.id === 'results') window.location.replace('/teacher/tests-results');
    else if (e.target.id === 'myTests') window.location.replace('/teacher/my-tests');
    else if (e.target.id === 'createTest') window.location.replace('/teacher/create-test');
  }

  handleChangeQuestion(e)
  {
    fetch(`${backendURL}/api/results/by-test/${e.target.value}`)
      .then((res) => res.json())
      .then(
        (result) => {
          this.setState({
            results: result.response.items,
            filteredResults: [],
          });
        },
        (error) => {
          console.log(error);
        },
      );
  }

  handleFilterButton(e)
  {
    const validResults = this.state.results.filter(obj => {
      return obj.user.classNumber === this.state.selectedClassNumber && obj.user.classChar === this.state.selectedClassChar;
    });
    this.setState({
      filteredResults: validResults,
    })
  }

  handleChangeClassNumber(e)
  {
    this.setState({
      selectedClassNumber: Number(e.target.value),
    });
  }

  handleChangeClassChar(e)
  {
    this.setState({
      selectedClassChar: e.target.value,
    });
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      if (!userIsTeacher) window.location.replace('/student/available-tests');
      fetch(`${backendURL}/api/test/by-user/${userID}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              tests: result.response.items,
              isLoaded: true,
            });
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  render() {
    const {
      tests, isLoaded, results, selectedClassNumber, selectedClassChar, filteredResults,
    } = this.state;

    if (!isLoaded) return <div>Loading</div>
    else {
      return (
        <div className="mb">
          <div className="container ">
            <div className="test_tab teacher_tab">
              <ul className="test_tab_nav">
                <li>
                  <button id="results" onClick={this.handlerOpenNewTab} className="active" data-content="students_result">Результаты учащихся</button>
                </li>
                <li>
                  <button id="createTest" onClick={this.handlerOpenNewTab} data-content="create_test">Создать тест</button>
                </li>
                <li>
                  <button id="myTests" onClick={this.handlerOpenNewTab} data-content="my_test">Мои тесты</button>
                </li>
              </ul>

              <div className="test_tab_body ">
                <div className="test_content active" id="students_result">
                  <div className="class_selection">
                    <label htmlFor="class_student">Выберите класс:</label>
                    <select value={selectedClassNumber && selectedClassNumber} name="class_student_number" id="class_student_number" onChange={this.handleChangeClassNumber}>
                      <option value="6">6</option>
                      <option value="7">7</option>
                      <option value="8">8</option>
                      <option value="9">9</option>
                      <option value="10">10</option>
                      <option value="11">11</option>
                    </select>
                    <select value={selectedClassChar && selectedClassChar} name="class_student_letters" id="class_student_letters" onChange={this.handleChangeClassChar}>
                      <option value="a">A</option>
                      <option value="b">Б</option>
                      <option value="v">В</option>
                      <option value="g">Г</option>
                      <option value="d">Д</option>
                    </select>
                    <button className={styles.editTestButton} onClick={this.handleFilterButton}>ОТФИЛЬТРОВАТЬ</button>
                    <div className="select_test">
                      <label htmlFor="select_test_id">Выберите тест:</label>
                      <select name="select_test_id" id="select_test_id" onChange={this.handleChangeQuestion} defaultValue={0}>
                        <option key={0} value={0} disabled={true}>Выберите тест</option>
                        {tests != null && tests.length > 0 && tests.map((test, index) => (
                          <option key={index} value={test.id}>{test.title}</option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="result_test_title">
                    Результаты тестирования:
                  </div>
                  {filteredResults.length < 1
                    && results != null && results.length > 0
                    && results.map((result, index) => (
                    <div className="test_main" key={index}>
                      <div className="test_main_title user_answer_title">
                        <div className="user_answer">
                          <p>{result.user.fio}</p>
                          <p>Отметка: {result.score}</p>
                        </div>
                      </div>
                      <div className="test_main_button">
                        <a href={`/teacher/view-results/${result.id}`}>ПОСМОТРЕТЬ РЕЗУЛЬТАТ</a>
                      </div>
                    </div>
                  ))}
                  {filteredResults && filteredResults.length > 0
                    && filteredResults.map((result, index) => (
                      <div className="test_main" key={index}>
                        <div className="test_main_title user_answer_title">
                          <div className="user_answer">
                            <p>{result.user.fio}</p>
                            <p>Отметка: {result.score}</p>
                          </div>
                        </div>
                        <div className="test_main_button">
                          <a href={`/teacher/view-results/${result.id}`}>ПОСМОТРЕТЬ РЕЗУЛЬТАТ</a>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

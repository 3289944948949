import React, { Component } from 'react';
import {
  clearLocalStorage,
  studentLevelClass,
  userIsAuth,
} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";

export default class AvailableTestsPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
    };
    this.handlerOpenNewTab = this.handlerOpenNewTab.bind(this);
  };

  handlerOpenNewTab(e)
  {
    window.location.replace('/student/passed-tests');
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      fetch(`${backendURL}/api/test/by-class/` + studentLevelClass)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              items: result.response.items,
            });
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  render() {
    const {
      items,
    } = this.state;
    return (
      <div className="mb_student">
        <div className="container">
          <div className="test_tab">
            <ul className="test_tab_nav">
              <li>
                <button className="active" data-content="available">Доступные тесты</button>
              </li>
              <li>
                <button data-content="passed" onClick={this.handlerOpenNewTab}>Пройденные тесты</button>
              </li>
            </ul>
            <div className="test_tab_body ">
              <div className="test_content active" id="available">
                {items && items.length > 0 && items.map((item) => (
                  <div className="test_main" key={item.id}>
                    <div className="test_main_title">
                      {item.title}
                    </div>
                      <div className="test_main_button">
                      <a href={`/student/take-test/${item.id}`}>ПРОЙТИ ТЕСТ</a>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

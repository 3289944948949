import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AppHeaderLayout } from './Header';
import { AppFooterLayout } from './Footer';
import {userIsAuth} from "../utils/localStorage";

const propTypes = {
  children: PropTypes.node.isRequired,
};

export default class AppMainLayout extends Component {
    render() {
    const {
      children,
    } = this.props;

    return (
      <React.Fragment>
        <div className="wrapper">
          <div className={`${!userIsAuth ? 'index_wrapper' : 'other_wrapper'}`}>
            <AppHeaderLayout />
            {children}
            {
              userIsAuth && (
                <AppFooterLayout />
              )
            }
          </div>
        </div>
      </React.Fragment>
    );
  }
}

AppMainLayout.propTypes = propTypes;

/**
 * Save login data
 * @param {Object} data
 */
export const saveLoginData = (data) => {
  localStorage.setItem('userID', data.id);
  localStorage.setItem('userFIO', data.fio);
  localStorage.setItem('userTeacher', data.teacher);
  localStorage.setItem('userLessonStudy', data.lessonStudy);
  localStorage.setItem('userClassNumber', data.classNumber);
  localStorage.setItem('userClassChar', data.classChar);
};

/**
 * Clear local storage
 */
export const clearLocalStorage = () => {
  localStorage.clear();
};

export const userIsAuth = localStorage.getItem('userID') !== null;

export const studentLevelClass = localStorage.getItem('userClassNumber');

export const userID = localStorage.getItem('userID');

export const userIsTeacher = localStorage.getItem('userTeacher') !== 'false';

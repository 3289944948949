import React from 'react';

import logo from '../../resources/logo.png';
import userLogoDefault from '../../resources/user.png';

function AppHeaderLayout() {
  return (
    <React.Fragment>
      <header className="header">
        <div className="container">
          <div className="logo">
            <img src={logo} alt="logo" />
              <p>Сервис онлайн-тестирования в сфере образования</p>
          </div>
          {
            localStorage.getItem('userID') && (
              <div className="user">
                <span>{localStorage.getItem('userFIO')}</span>
                <img src={userLogoDefault} alt="" />
              </div>
            )
          }
        </div>
      </header>
    </React.Fragment>
  );
}

export default AppHeaderLayout;

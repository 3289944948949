import React from 'react';
import PropTypes from 'prop-types';
import QuestionsView from "./QuestionsView";

const propTypes = {
  testTitle: PropTypes.string.isRequired,
  testedUserFio: PropTypes.string.isRequired,
  score: PropTypes.number.isRequired,
  resultID: PropTypes.number.isRequired,
};

const defaultProps = {

};

export default function MainView(props) {
  const {
    testTitle,
    testedUserFio,
    score,
    resultID,
  } = props;

  return (
    <React.Fragment>
      <h1>{testTitle}</h1>
      <div className="user">
        Тестируемый: {testedUserFio}
      </div>
      <div className="mark">
        Отметка: {score}
      </div>
      <div className="test_body_scroll">
        <QuestionsView
          resultID={resultID}
        />
      </div>
    </React.Fragment>
  );
}

MainView.propTypes = propTypes;
MainView.defaultProps = defaultProps;

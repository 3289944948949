import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from "./style.module.scss";

const propTypes = {
  published: PropTypes.bool.isRequired,
  testID: PropTypes.number,
  handleRemoveTest: PropTypes.any,
  handlePublishTest: PropTypes.any,
};

const defaultProps = {
  testID: undefined,
  handleRemoveTest: undefined,
  handlePublishTest: undefined,
};

export default class TeacherEditButtonsBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
    };

    this.handleRemoveTest = this.handleRemoveTest.bind(this);
    this.handlePublishTest = this.handlePublishTest.bind(this);
  };

  handleRemoveTest(e)
  {
    e.preventDefault();
    this.props.handleRemoveTest(this.props.testID);
  }

  handlePublishTest(e)
  {
    e.preventDefault();
    this.props.handlePublishTest(this.props.testID);
  }

  render() {
    const {
      published, testID,
    } = this.props;
    return (
      <React.Fragment>
        {!published && (
          <div className={styles.editTestButton}>
            <a style={{color: "white"}} href={`/teacher/my-tests/edit/${testID}`}>РЕДАКТИРОВАТЬ</a>
          </div>
        )}
        {!published && (
          <button className={styles.publishTestButton} onClick={this.handlePublishTest}>ОПУБЛИКОВАТЬ</button>
          )}
        {published && (
          <div className={styles.editTestButton}>
            <a style={{color: "white"}} href={`/teacher/my-tests/view/${testID}`}>ОБЗОР</a>
          </div>
        )}
        <button className={styles.removeTestButton} onClick={this.handleRemoveTest}>УДАЛИТЬ</button>
      </React.Fragment>
    );
  };
}

TeacherEditButtonsBlock.propTypes = propTypes;
TeacherEditButtonsBlock.defaultProps = defaultProps;

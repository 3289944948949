import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {backendURL} from "../../utils/env";

const propTypes = {
  questionID: PropTypes.number.isRequired,
  selectedAnswer: PropTypes.object.isRequired,
};

const defaultProps = {
};

export default class AnswersInputView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      items: [],
      rightTitleAnswer: undefined,
    }
  }
  componentDidMount() {
    fetch(`${backendURL}/api/answer-to-question/by-question/${this.props.questionID}`)
      .then((res) => res.json())
      .then(
        (result) => {
          const rightAnswer = result.response.items.find(obj => {
            return obj.rightAnswer;
          });
          this.setState({
            isLoaded: true,
            items: result.response.items,
            rightTitleAnswer: rightAnswer.title,
          })
        },
        (error) => {
          console.log(error);
        },
      );
  }

  render() {
    const {
      isLoaded, items, rightTitleAnswer,
    } = this.state;

    const {
      selectedAnswer,
    } = this.props;

    if (!isLoaded) return <div>Loading...</div>
    else return (
      <React.Fragment>
        {items.map((item, index) => (
          <div className="option" key={index}>
            <label>
              <input type="radio" name={item.id} id={item.id} checked={selectedAnswer.id === item.id} disabled />
              {item.title}
            </label>
          </div>
        ))}
        {selectedAnswer.rightAnswer ? (
          <div className="correct_answer">
            Ответ верный.
          </div>
        ) : (
          <div className="incorrect_answer">
            Ответ неверный.
            <span>Верный ответ: {rightTitleAnswer}</span>
          </div>
        )}
      </React.Fragment>
    );
  };
}

AnswersInputView.propTypes = propTypes;
AnswersInputView.defaultProps = defaultProps;

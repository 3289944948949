/**
 * Validate fio field
 *
 * @param {!string} fio
 * @returns {boolean}
 */
export const validateFio = (fio) => {
  if (!fio) return false;

  if (fio.length > 254) return false;

  const parts = fio.split(' ');

  if (parts.length !== 3) return false;

  if (parts[0].length < 2 || parts[1].length < 2 || parts[2].length < 2) return false;

  return true;
};

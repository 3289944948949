import React, { Component } from 'react';
import {
  clearLocalStorage,
  userIsAuth,
} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";
import MainView from "../../components/ViewResult/MainView";

export default class ViewResult extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      test: {
        id: undefined,
        title: undefined,
      },
      user: {
        fio: undefined,
      },
      score: undefined,
      resultID: undefined,
    }
  };

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      fetch(`${backendURL}/api/result/${this.props.match.params.resultID}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState({
              isLoaded: true,
              test: {
                id: result.response.test.id,
                title: result.response.test.title,
              },
              user: {
                fio: result.response.user.fio,
              },
              score: result.response.score,
              resultID: result.response.id,
            })
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  render() {
    const {
      isLoaded,
      test,
      user,
      score,
      resultID,
    } = this.state;
    if (!isLoaded) return <div>Loading...</div>
    else return (
      <div className="container">
        <div className="main view_result">
          <div className="end_review">
            <a href={'/student/passed-tests'}>ЗАКОНЧИТЬ ОБЗОР</a>
          </div>
          <MainView
            testTitle={test.title}
            testedUserFio={user.fio}
            score={score}
            resultID={resultID}
          />
        </div>
      </div>
    );
  };
}

import React, { Component } from 'react';

import QuestionItem from "../../components/QuestionItem";
import CreateQuestionForm from "../../forms/CreateQuestion";
import {clearLocalStorage, userIsAuth, userIsTeacher} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";

export default class ViewTest extends Component {
  constructor() {
    super();

    this.state = {
      questions: [],
      test: undefined,
      isLoadedTest: false,
      isLoadedQuestions: false,
      selectedQuestion: undefined,
    };

    this.handlerOpenNewTab = this.handlerOpenNewTab.bind(this);
    this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
  }

  handlerOpenNewTab(e)
  {
    if (e.target.id === 'results') window.location.replace('/teacher/tests-results');
    else if (e.target.id === 'myTests') window.location.replace('/teacher/my-tests');
    else if (e.target.id === 'createTest') window.location.replace('/teacher/create-test');
  }

  handleSelectQuestion(e)
  {
    this.setState({
      selectedQuestion: e,
    });
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      if (!userIsTeacher) window.location.replace('/student/available-tests');
      else {
        fetch(`${backendURL}/api/test/${this.props.match.params.testID}`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState({
                test: result.response,
                isLoadedTest: true,
              });
            },
            (error) => {
              console.log(error);
            },
          );

        fetch(`${backendURL}/api/questions/${this.props.match.params.testID}`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState({
                questions: result.response.items,
                isLoadedQuestions: true,
              });
            },
            (error) => {
              console.log(error);
            },
          );
      }
    }
  }

  render() {
    const {
      questions, test, isLoadedTest, isLoadedQuestions, selectedQuestion,
    } = this.state;

    if (!isLoadedTest || !isLoadedQuestions) return <div>Loading...</div>
    else return (
      <div className="mb">
        <div className="container ">
          <div className="test_tab teacher_tab">
            <ul className="test_tab_nav">
              <li>
                <button onClick={this.handlerOpenNewTab} id="results" data-content="students_result">Результаты учащихся</button>
              </li>
              <li>
                <button onClick={this.handlerOpenNewTab} id="createTest" data-content="create_test">Создать тест</button>
              </li>
              <li>
                <button onClick={this.handlerOpenNewTab} id="myTests" className="active" data-content="my_test">Мои тесты</button>
              </li>
            </ul>
              <div className="test_content active" id="create_test">
                <div className="test_name">
                  <label htmlFor="test_title">Название теста:</label>
                  <input disabled={true} type="text" name="test_title" id="test_title" placeholder="Введите название теста" value={test.title}/>
                </div>
                <div className="max_questions">
                  <label htmlFor="max_questions_title">Максимально вопросов в тесте:</label>
                  <input disabled={true} type="number" name="max_questions_title" id="max_questions_title" value={test.maxQuestions}/>
                </div>
                <div className="max_questions">
                  <label htmlFor="max_questions_class">Классы: </label>
                  <input disabled={true} type="number" name="max_questions_class" id="max_questions_class" value={test.forClass}/>
                </div>
                <div className="save_questions">
                  <div className="save_questions_left" id="save_questions_left">
                    {questions.map((item, index) => (
                      <QuestionItem
                        questionTitle={item.title}
                        questionNumber={item.id}
                        key={index}
                        viewPage={true}
                        questionSelected={selectedQuestion ? selectedQuestion.id : undefined}
                        handleSelectQuestion={this.handleSelectQuestion}
                        questionLevel={item.level}
                      />
                    ))}
                  </div>
                  {selectedQuestion && (
                    <CreateQuestionForm
                      viewPage={true}
                      question={selectedQuestion}
                    />
                  )}
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

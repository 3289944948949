import React, { Component } from 'react';

import {clearLocalStorage, userID, userIsAuth, userIsTeacher} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";
import TeacherEditButtonsBlock from "./components/TeacherEditButtonsBlock";

export default class MyTests extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tests: [],
      selectedClass: 6,
    };

    this.handlerOpenNewTab = this.handlerOpenNewTab.bind(this);
    this.handleSelectClass = this.handleSelectClass.bind(this);
    this.handleRemoveTest = this.handleRemoveTest.bind(this);
    this.handlePublishTest = this.handlePublishTest.bind(this);
  }

  handlerOpenNewTab(e)
  {
    console.log(e.target.id);
    if (e.target.id === 'results') window.location.replace('/teacher/tests-results');
    else if (e.target.id === 'myTests') window.location.replace('/teacher/my-tests');
    else if (e.target.id === 'createTest') window.location.replace('/teacher/create-test');
  }

  handleSelectClass(e)
  {
    this.setState({selectedClass: e.target.value});
  }

  handleRemoveTest(e)
  {
    fetch(`${backendURL}/api/test/${e}`, {
      method: 'DELETE',
    })
      .then((res) => res.json())
      .then(
        () => {
          const newArrTests = [...this.state.tests];
          const findQuestion = newArrTests.indexOf(newArrTests.find(obj => {
            return obj.id === e;
          }));
          newArrTests.splice(findQuestion, 1);
          this.setState({
            tests: newArrTests,
          });
        },
        (error) => {
          console.log(error);
        },
      );
  }

  handlePublishTest(e)
  {
    fetch(`${backendURL}/api/test/publish/${e}`, {
      method: 'PATCH',
    })
      .then((res) => res.json())
      .then(
        (result) => {
          let newArrTests = [...this.state.tests];
          const findQuestion = newArrTests.indexOf(newArrTests.find(obj => {
            return obj.id === e;
          }));
          newArrTests[findQuestion] = result.response;
          this.setState({
            tests: newArrTests,
          });
        },
        (error) => {
          console.log(error);
        },
      );
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      if (!userIsTeacher) window.location.replace('/student/available-tests');
      else {
        fetch(`${backendURL}/api/test/by-user/${userID}`)
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.meta.error === null) {
                this.setState({tests: result.response.items})
              } else alert(result.meta.error);
            },
            (error) => {
              console.log(error);
            },
          );
      }
    }
  }

  render() {
    const {
      tests, selectedClass,
    } = this.state;

    return (
      <div className="mb">
        <div className="container ">
          <div className="test_tab teacher_tab">
            <ul className="test_tab_nav">
              <li>
                <button id="results" onClick={this.handlerOpenNewTab} data-content="students_result">Результаты учащихся</button>
              </li>
              <li>
                <button id="createTest" onClick={this.handlerOpenNewTab} data-content="create_test">Создать тест</button>
              </li>
              <li>
                <button id="myTests" onClick={this.handlerOpenNewTab} className="active" data-content="my_test">Мои тесты</button>
              </li>
            </ul>

            <div className="test_tab_body ">
              <div className="test_content active" id="my_test">
                <div className="class_selection">
                  <label htmlFor="class_student">Выберите класс:</label>
                  <select onChange={this.handleSelectClass} value={selectedClass} name="class_student_number" id="class_student_number">
                    <option value="6">6</option>
                    <option value="7">7</option>
                    <option value="8">8</option>
                    <option value="9">9</option>
                    <option value="10">10</option>
                    <option value="11">11</option>
                  </select>
                </div>
                {tests.map((test, index) => {
                  if (test.forClass === Number(selectedClass))
                    return <div className="test_main" key={index}>
                      <div className="test_main_title">
                        {test.title}
                        {/*<a className="test_main_button_mb" href="#">РЕДАКТИРОВАТЬ</a>*/}
                      </div>
                      <TeacherEditButtonsBlock
                        published={test.published}
                        handleRemoveTest={this.handleRemoveTest}
                        testID={test.id}
                        handlePublishTest={this.handlePublishTest}
                      />
                    </div>
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

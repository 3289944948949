import React, { Component } from 'react';
import {
  clearLocalStorage,
  userIsAuth,
} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";
import AnswerToQuestion from "./components/AnswerToQuestion";
import {getRandomFiveQuestionsByUserLevel} from "../../utils/TestingAlgoritm";
import {saveAnsweredResult, saveResult} from "../../utils/network";

export default class TakeTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoaded: false,
      test: undefined,
      questions: [],
      userTestLevel: 2,
      userStartAnswerToFiveQuestions: false,
      userNeedToAnswerQuestions: [],
      userShowedTest: undefined,
      userAnsweredQuestion: [],
      rightAnswered: [0, 0 , 0 , 0, 0, 0],
      rightAnsweredOnCurrentLevel: 0,
      tryingCount: [0, 0 , 0 , 0, 0, 0],
      tryingCountOnCurrentLevel: 0,
      score: undefined,
      lockedSendResultButton: false,
    };
    this.getBackData = this.getBackData.bind(this);
    this.startTest = this.startTest.bind(this);
    this.handleAcceptAnswer = this.handleAcceptAnswer.bind(this);
    this.saveResult = this.saveResult.bind(this);
  };

  async getBackData()
  {
    let getTestID = undefined;
    await fetch(`${backendURL}/api/test/${this.props.match.params.testID}`)
      .then((res) => res.json())
      .then(
        (result) => {
          getTestID = result.response.id;
          this.setState({
            test: result.response,
          });
        },
        (error) => {
          console.log(error);
        },
      );
    await fetch(`${backendURL}/api/questions/${getTestID}`)
      .then((res) => res.json())
      .then(
        (result) => {
          Array.from(result.response.items).forEach(async (question) => {
            await fetch(`${backendURL}/api/answer-to-question/by-question/${question.id}`)
              .then((res) => res.json())
              .then(
                (result) => {
                  question.answers = result.response.items;
                  this.setState(prevState => ({
                    questions: [...prevState.questions, question],
                  }));
                },
                (error) => {
                  console.log(error);
                },
              );
          });
        },
        (error) => {
          console.log(error);
        },
      );
  }

  startTest()
  {
    if (!this.state.userStartAnswerToFiveQuestions) {
      let allQuestions = this.state.questions;
      const nextFiveQuestions = getRandomFiveQuestionsByUserLevel(allQuestions, this.state.userTestLevel);
      if (nextFiveQuestions !== null) {
        nextFiveQuestions.map((question) => {
          const findQuestion = allQuestions.indexOf(allQuestions.find(obj => {
            return obj.id === question.id;
          }))
          allQuestions.splice(findQuestion, 1);
        })
        this.setState({
          userStartAnswerToFiveQuestions: true,
          userNeedToAnswerQuestions: nextFiveQuestions,
          userShowedTest: nextFiveQuestions[0],
        });
      }
    }
  }

  async componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      await this.getBackData();
      this.setState({
        isLoaded: true,
      });
    }
  }

  /**
   * Save result
   * @param {!number} score
   * @return {Promise<void>}
   */
  async saveResult(score)
  {
    this.setState({
      lockedSendResultButton: true,
    });
    const createdResult = await saveResult(this.state.test.id, score);
    const answeredQuestion = this.state.userAnsweredQuestion;
    answeredQuestion.map(async (answer) => {
      await saveAnsweredResult(createdResult, answer.questionID, answer.answerID);
    })
    alert("Вы закончили прохождение теста! Сейчас откроется страница с результатми");
    setTimeout(() => {
      window.location.replace('/student/passed-tests');
    }, 5000);
  }

  handleAcceptAnswer(e)
  {
    const allAnsweredQuestion = this.state.userAnsweredQuestion.length + 1;
    this.setState(prevState => ({
      userAnsweredQuestion: [...prevState.userAnsweredQuestion, {
        questionID: this.state.userShowedTest.id,
        answerID: Number(e.selectedAnswer),
      }],
    }));
    const answers = this.state.userShowedTest.answers;
    const rightAnswer = answers.filter(obj => {
      return obj.rightAnswer === true;
    })[0];
    let userCurrentLevel = this.state.userTestLevel;

    // начисление правильных ответов в зависимости от уровня
    let currentRightAnswer = this.state.rightAnswered;
    let newCurrentRightAnswer = currentRightAnswer[userCurrentLevel];
    let rightAnsweredOnCurrentLevel = this.state.rightAnsweredOnCurrentLevel;
    if (Number(e.selectedAnswer) === rightAnswer.id) {
      newCurrentRightAnswer++;
      rightAnsweredOnCurrentLevel++;
      currentRightAnswer[userCurrentLevel] = newCurrentRightAnswer;
      this.setState(({
        rightAnswered: currentRightAnswer,
        rightAnsweredOnCurrentLevel: rightAnsweredOnCurrentLevel,
      }));
    }

    // удаление пройденного теста из массива выдданных тестов
    let allUserQuestions = this.state.userNeedToAnswerQuestions;
    const findQuestion = allUserQuestions.indexOf(allUserQuestions.find(obj => {
      return obj.id === this.state.userShowedTest.id;
    }))
    allUserQuestions.splice(findQuestion, 1);

    if (allUserQuestions.length > 0) {
      this.setState({
        userShowedTest: allUserQuestions[0],
      });
    } else {
      if (rightAnsweredOnCurrentLevel >= 4) {
        if (userCurrentLevel === 4) {
          this.setState({score: 5});
          this.saveResult(5);
        }
        else {
          if (allAnsweredQuestion >= this.state.test.maxQuestions) {
            const maxRightAnswers = currentRightAnswer.indexOf(Math.max(...currentRightAnswer));
            this.setState({score: maxRightAnswers + 1})
            this.saveResult(maxRightAnswers + 1);
          } else {
            this.setState({
              userTestLevel: userCurrentLevel + 1,
              userStartAnswerToFiveQuestions: false,
              tryingCountOnCurrentLevel: 0,
              rightAnsweredOnCurrentLevel: 0,
            });
            this.startTest();
          }
        }
      }
      else if (rightAnsweredOnCurrentLevel < 3) {
        if (userCurrentLevel === 1) {
          this.setState({score: 2});
          this.saveResult(2);
        }
        else {
          if (allAnsweredQuestion >= this.state.test.maxQuestions) {
            const maxRightAnswers = currentRightAnswer.indexOf(Math.max(...currentRightAnswer));
            this.setState({score: maxRightAnswers + 1})
            this.saveResult(maxRightAnswers + 1);
          } else {
            this.setState({
              userTestLevel: userCurrentLevel - 1,
              userStartAnswerToFiveQuestions: false,
              tryingCountOnCurrentLevel: 0,
              rightAnsweredOnCurrentLevel: 0,
            });
            this.startTest();
          }
        }
      }
      else {
        const currentTrying = this.state.tryingCountOnCurrentLevel + 1;
        if (currentTrying > 1) {
          this.setState({score: userCurrentLevel + 1})
          this.saveResult(userCurrentLevel + 1);
        }
        else {
          this.setState({
            tryingCountOnCurrentLevel: currentTrying,
            rightAnsweredOnCurrentLevel: 0,
            userStartAnswerToFiveQuestions: false,
          });
          this.startTest();
        }
      }
    }
  }

  render() { // TODO: рефакторинг - вынести получение ответов на вопросы в дочерний компонент
    const {
      test,
      isLoaded,
      userStartAnswerToFiveQuestions,
      userShowedTest,
      lockedSendResultButton,
    } = this.state;
    if (isLoaded) {
      this.startTest();
      if (userStartAnswerToFiveQuestions && userShowedTest) {
        return (
          <div className="mb">
            <div className="container">
              <div className="main">
                <h1>{test.title}</h1>
                <AnswerToQuestion
                  title={userShowedTest.title}
                  answers={userShowedTest.answers}
                  handleAcceptAnswer={this.handleAcceptAnswer}
                  lockedSendResultButton={lockedSendResultButton}
                />
              </div>
            </div>
          </div>
        );
      } else {
        return <div>Loading...</div>;
      }
    } else {
      return <div>Loading...</div>;
    }
  };
}

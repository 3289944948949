import React, { Component } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  title: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  handleAcceptAnswer: PropTypes.any.isRequired,
  lockedSendResultButton: PropTypes.bool.isRequired,
};

export default class AnswerToQuestion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedAnswer: undefined,
    };
    this.handleOnSelectAnswer = this.handleOnSelectAnswer.bind(this);
    this.handleClickToAcceptButton = this.handleClickToAcceptButton.bind(this);
  };

  handleOnSelectAnswer(e)
  {
    this.setState({
      selectedAnswer: Number(e.target.value),
    });
  }

  handleClickToAcceptButton(e)
  {
    e.preventDefault();
    if (this.state.selectedAnswer) {
      const currentState = this.state;
      this.setState({selectedAnswer: undefined})
      this.props.handleAcceptAnswer(currentState);
    }
    else alert('Не выбран ответ!');
  }

  render() {
    const {
      title, answers, lockedSendResultButton,
    } = this.props;

    const {
      selectedAnswer,
    } = this.state;

    return (
      <React.Fragment>
        <div className="test_body">
          <div className="test_body_title">
            {title}
          </div>
          <div className="test_body_subtitle">
            Выберите один верный ответ.
          </div>
          <form>
            {answers.map((answer, index) => (
              <div className="option" key={index}>
                <label>
                  <input
                    value={answer.id}
                    onChange={this.handleOnSelectAnswer}
                    type="radio"
                    name="answer"
                    checked={answer.id === selectedAnswer}
                  />
                  {answer.title}
                </label>
              </div>
            ))}
            <div className="confirm">
              <button disabled={lockedSendResultButton} onClick={this.handleClickToAcceptButton}>ПОДТВЕРДИТЬ ОТВЕТ</button>
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  };
}

AnswerToQuestion.propTypes = propTypes;
// TeacherEditButtonsBlock.defaultProps = defaultProps;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import trashImage from '../resources/trashcan.png';
import {backendURL} from "../utils/env";

const propTypes = {
  question: PropTypes.object,
  handleSaveQuestion: PropTypes.any,
  viewPage: PropTypes.bool,
  editPage: PropTypes.bool,
};

const defaultProps = {
  question: undefined,
  handleSaveQuestion: undefined,
  viewPage: false,
  editPage: false,
};

export default class CreateQuestionForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questionTitle: undefined,
      questionLevel: 1,
      answers: [],
      editedQuestionTitle: undefined,
      editedQuestionLevel: undefined,
      editedAnswers: [],
      needToDeleteAnswers: [],
    };
    this.addNewAnswer = this.addNewAnswer.bind(this);
    this.handleCheckRightAnswer = this.handleCheckRightAnswer.bind(this);
    this.handleChangeAnswerTitle = this.handleChangeAnswerTitle.bind(this);
    this.handleRemoveAnswer = this.handleRemoveAnswer.bind(this);
    this.handleClickSaveButton = this.handleClickSaveButton.bind(this);
    this.handleChangeQuestionTitle = this.handleChangeQuestionTitle.bind(this);
    this.handleChangeQuestionLevel = this.handleChangeQuestionLevel.bind(this);
  }

  addNewAnswer(e) {
    e.preventDefault();
    if (!this.props.editPage) {
      this.setState(prevState => ({
        answers: [...prevState.answers, {title: '', rightAnswer: false}]
      }))
    }
    else {
      this.setState(prevState => ({
        editedAnswers: [...prevState.editedAnswers, {title: '', rightAnswer: false, needToCreate: true}]
      }))
    }
  }

  handleChangeQuestionTitle(e) {
    if (!this.props.editPage) this.setState({questionTitle: e.target.value});
    else this.setState({editedQuestionTitle: e.target.value});
  }

  handleChangeQuestionLevel(e) {
    if (!this.props.editPage) this.setState({questionLevel: e.target.value});
    else this.setState({editedQuestionLevel: Number(e.target.value)});
  }

  handleCheckRightAnswer(e) {
    if (!this.props.editPage) {
      const newItems = [...this.state.answers];
      const findEnabledRightA = newItems.indexOf(newItems.find(obj => {
        return obj.rightAnswer === true;
      }));
      if (findEnabledRightA >= 0) newItems[findEnabledRightA].rightAnswer = false;
      newItems[e.target.id].rightAnswer = !newItems[e.target.id].rightAnswer;
      this.setState({answers: newItems});
    }
    else {
      const newItems = [...this.state.editedAnswers];
      const findEnabledRightA = newItems.indexOf(newItems.find(obj => {
        return obj.rightAnswer === true;
      }));
      newItems[findEnabledRightA].rightAnswer = false;
      newItems[e.target.id].rightAnswer = !newItems[e.target.id].rightAnswer;
      this.setState({editedAnswers: newItems});
    }
  }

  handleChangeAnswerTitle(e) {
    if (!this.props.editPage) {
      const newItems = [...this.state.answers];
      newItems[e.target.id].title = e.target.value;
      this.setState({answers: newItems});
    }
    else {
      const newItems = [...this.state.editedAnswers];
      newItems[e.target.id].title = e.target.value;
      this.setState({editedAnswers: newItems});
    }
  }

  handleRemoveAnswer(e) {
    if (!this.props.editPage) {
      const newArr = [...this.state.answers];
      newArr.splice(e.target.id, 1);
      this.setState({answers: newArr});
    }
    else {
      const newArr = [...this.state.editedAnswers];
      newArr.splice(e.target.id, 1);
      this.setState({editedAnswers: newArr});
      if (Number(e.target.name) !== 0) {
        this.setState(prevState => ({
          needToDeleteAnswers: [...prevState.needToDeleteAnswers, {id: Number(e.target.name)}]
        }));
      }
    }
  }

  handleClickSaveButton(e) {
    e.preventDefault();
    if (!this.props.editPage) {
      if (this.state.answers && this.state.answers.length > 1
        && this.state.questionTitle
      ) {
        this.props.handleSaveQuestion(this.state);
        this.setState({answers: []});
      }
      else alert('Неправильно заполненны поля!');
    } else this.props.handleSaveQuestion(this.state);
  }

  componentDidMount() {
    if ((this.props.viewPage || this.props.editPage) && this.props.question) {
      fetch(`${backendURL}/api/answer-to-question/by-question/${this.props.question.questionNumber}`)
        .then((res) => res.json())
        .then(
          (result) => {
            this.setState(({
              answers: result.response.items,
              editedAnswers: result.response.items,
              needToDeleteAnswers: [],
            }));
          },
          (error) => {
            console.log(error);
          },
        );
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if ((this.props.viewPage || this.props.editPage) && this.props.question) {
      if (prevProps.question !== this.props.question) {
        fetch(`${backendURL}/api/answer-to-question/by-question/${this.props.question.questionNumber}`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState(({
                answers: result.response.items,
                editedAnswers: result.response.items,
                editedQuestionTitle: undefined,
                editedQuestionLevel: undefined,
                needToDeleteAnswers: [],
              }));
            },
            (error) => {
              console.log(error);
            },
          );
      }
    }
  }

  render() {
    const {
      question, viewPage,
      editPage,
    } = this.props;

    const {
      answers,
      editedQuestionTitle,
      editedQuestionLevel,
      editedAnswers,
    } = this.state;
    return (
      <div className="new_question">
        <div className="edit_question">
          <label htmlFor="add_question">Вопрос: #{question ? question.questionNumber : question}</label>
          <textarea
            onChange={this.handleChangeQuestionTitle}
            className="add_question"
            placeholder="Введите вопрос"
            cols="30"
            rows="1"
            value={
              editedQuestionTitle ? editedQuestionTitle : question ? question.questionTitle : undefined
            }
            disabled={viewPage}
          ></textarea>
        </div>
        <div className="question_difficulty_level">
          <label htmlFor="question_difficulty_level_label">Уровень сложности вопроса:</label>
          <select disabled={viewPage} value={editedQuestionLevel ? editedQuestionLevel : question ? question.questionLevel : undefined} onChange={this.handleChangeQuestionLevel} name="question_difficulty_level_label" id="question_difficulty_level_label">
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
          </select>
        </div>
        <div className="answer_option">
          {!viewPage && (<button className="add_button" onClick={this.addNewAnswer}>ДОБАВИТЬ ВАРИАНТ ОТВЕТА</button>)}
          {editPage ? editedAnswers.map((item, index) => (
            <div className="answer_option_item" key={index} id={index}>
              <input
                type="text"
                value={item.title}
                placeholder="Введите вариант ответа"
                onChange={this.handleChangeAnswerTitle}
                id={index}
                disabled={false}
              />
              <input
                onChange={this.handleCheckRightAnswer}
                type="checkbox"
                id={index}
                checked={item.rightAnswer}
                disabled={false}
              />
              {!viewPage && (
                <a className="remove_button" name={item.id} id={index} onClick={this.handleRemoveAnswer}>
                  <img id={index} name={item.id} src={trashImage}  alt='' />
                </a>
              )}
            </div>
          )) : answers.map((item, index) => (
            <div className="answer_option_item" key={index} id={index}>
              <input
                type="text"
                value={item.title}
                placeholder="Введите вариант ответа"
                onChange={this.handleChangeAnswerTitle}
                id={index}
                disabled={viewPage}
              />
              <input
                onChange={this.handleCheckRightAnswer}
                type="checkbox"
                id={index}
                checked={item.rightAnswer}
                disabled={viewPage}
              />
              {!viewPage && (
                <a className="remove_button" id={index} onClick={this.handleRemoveAnswer}>
                  <img id={index} src={trashImage}  alt='' />
                </a>
              )}
            </div>
          ))}
        </div>
        {!viewPage && (<button onClick={this.handleClickSaveButton} className="add_btn">СОХРАНИТЬ ВОПРОС</button>)}
      </div>
    );
  }
}

CreateQuestionForm.propTypes = propTypes;
CreateQuestionForm.defaultProps = defaultProps;


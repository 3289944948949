import React, { Component } from 'react';

import QuestionItem from "../../components/QuestionItem";
import CreateQuestionForm from "../../forms/CreateQuestion";
import {clearLocalStorage, userIsAuth, userIsTeacher} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";

export default class EditTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      questions: [],
      test: undefined,
      isLoadedTest: false,
      isLoadedQuestions: false,
      selectedQuestion: undefined,
      newTestTitle: undefined,
      newTestClass: undefined,
      newTestMaxQuestions: undefined,
    };

    this.handlerOpenNewTab = this.handlerOpenNewTab.bind(this);
    this.handleSelectQuestion = this.handleSelectQuestion.bind(this);
    this.handleChangeTestTitle = this.handleChangeTestTitle.bind(this);
    this.handleChangeMaxQuestion = this.handleChangeMaxQuestion.bind(this);
    this.handleChangeForClass = this.handleChangeForClass.bind(this);
    this.handleClickSaveTestButton = this.handleClickSaveTestButton.bind(this);
    this.handleRemoveQuestionButton = this.handleRemoveQuestionButton.bind(this);
    this.handleSaveQuestion = this.handleSaveQuestion.bind(this);
  }

  handlerOpenNewTab(e)
  {
    if (e.target.id === 'results') window.location.replace('/teacher/tests-results');
    else if (e.target.id === 'myTests') window.location.replace('/teacher/my-tests');
    else if (e.target.id === 'createTest') window.location.replace('/teacher/create-test');
  }

  handleSelectQuestion(e)
  {
    this.setState({
      selectedQuestion: e,
    });
  }

  handleChangeTestTitle(e) {
    this.setState({
      newTestTitle: e.target.value,
    });
  }

  handleChangeMaxQuestion(e) {
    this.setState({
      newTestMaxQuestions: e.target.value,
    });
  }

  handleChangeForClass(e) {
    this.setState({
      newTestClass: e.target.value,
    });
  }

  handleClickSaveTestButton() {
    const formData = new FormData();
    if (this.state.newTestTitle) formData.append("title", this.state.newTestTitle);
    if (this.state.newTestMaxQuestions) formData.append("maxQuestions", this.state.newTestMaxQuestions);
    if (this.state.newTestClass) formData.append("forClass", this.state.newTestClass);
    fetch(`${backendURL}/api/test/update/${this.state.test.id}`, {
      method: 'POST',
      body: formData
    })
      .then((res) => res.json())
      .then(
        (result) => {
          if (result.meta.error === null) {
            this.setState({
              test: result.response,
            });
            alert("Тест успешно обновлен");
          } else alert(result.meta.error);
        },
        (error) => {
          console.log(error);
        },
      );
    this.setState({
      newTestTitle: undefined,
      newTestClass: undefined,
      newTestMaxQuestions: undefined,
    });
  }

  handleRemoveQuestionButton(e)
  {
      fetch(`${backendURL}/api/question/${Number(e)}`, {
        method: 'DELETE',
      })
        .then((res) => res.json())
        .then(
          () => {
            const newArrQuestions = [...this.state.questions];
            const findQuestion = newArrQuestions.indexOf(newArrQuestions.find(obj => {
              return obj.id === Number(e);
            }));
            newArrQuestions.splice(findQuestion, 1);
            this.setState({
              questions: newArrQuestions,
              selectedQuestion: undefined
            });
            alert("Тест успешно удален");
          },
          (error) => {
            console.log(error);
          },
        );
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      if (!userIsTeacher) window.location.replace('/student/available-tests');
      else {
        fetch(`${backendURL}/api/test/${this.props.match.params.testID}`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState({
                test: result.response,
                isLoadedTest: true,
              });
            },
            (error) => {
              console.log(error);
            },
          );

        fetch(`${backendURL}/api/questions/${this.props.match.params.testID}`)
          .then((res) => res.json())
          .then(
            (result) => {
              this.setState({
                questions: result.response.items,
                isLoadedQuestions: true,
              });
            },
            (error) => {
              console.log(error);
            },
          );
      }
    }
  }

  handleSaveQuestion(e)
  {
    // изменение заголовка вопроса и уровня вопроса
    if (e.editedQuestionTitle || e.editedQuestionLevel) {
      const formdata = new FormData();
      if (e.editedQuestionTitle) formdata.append("title", e.editedQuestionTitle);
      if (e.editedQuestionLevel) formdata.append("level", e.editedQuestionLevel);

      fetch(`${backendURL}/api/question/${this.state.selectedQuestion.questionNumber}`, {
        method: 'POST',
        body: formdata
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.meta.error === null) {
              const questionS = [...this.state.questions];
              const findQuestion = questionS.indexOf(questionS.find(obj => {
                return obj.id === this.state.selectedQuestion.questionNumber;
              }));
              questionS[findQuestion] = result.response;
              this.setState({questions: questionS});
            } else console.log(result.meta.error);
          },
          (error) => {
            console.log(error);
          },
        );
    }

    // удаление ответа на вопрос
    if (e.needToDeleteAnswers.length > 0) {
      e.needToDeleteAnswers.map((answer) => {
        fetch(`${backendURL}/api/answer-to-question/${answer.id}`, {
          method: 'DELETE',
        })
          .then((res) => res.json())
          .then(
            (result) => {
              if (result.meta.error !== null) console.log(result.meta.error);
            },
            (error) => {
              console.log(error);
            },
          );
      })
    }

    // создание новых вопросов
    const answersForCreate = e.editedAnswers.filter(obj => {
      return obj.needToCreate === true;
    });
    answersForCreate.map((answer) => {
      const formdata = new FormData();
      formdata.append("title", answer.title);
      formdata.append("right", answer.rightAnswer);
      formdata.append("forQuestion", this.state.selectedQuestion.questionNumber);

      fetch(`${backendURL}/api/answer-to-question`, {
        method: 'POST',
        body: formdata
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.meta.error !== null) {
              console.log(result.meta.error);
            }
          },
          (error) => {
            console.log(error);
          },
        );
    });

    // обновить вопросы
    const answersForUpdate = e.editedAnswers.filter(obj => {
      return obj.id > 0 && obj.id !== undefined;
    });
    answersForUpdate.map((answer) => {
      const currentVar = e.answers.filter(obj => {
        return obj.id === answer.id;
      })[0];
      if (currentVar) {
        if (currentVar.title !== answer.title || currentVar.rightAnswer !== answer.rightAnswer) {
          console.log(answer);
        }
      }
    })
  }

  render() {
    const {
      questions,
      test,
      isLoadedTest,
      isLoadedQuestions,
      selectedQuestion,
      newTestTitle,
      newTestMaxQuestions,
      newTestClass,
    } = this.state;

    if (!isLoadedTest || !isLoadedQuestions) return <div>Loading...</div>
    else return (
      <div className="mb">
        <div className="container ">
          <div className="test_tab teacher_tab">
            <ul className="test_tab_nav">
              <li>
                <button onClick={this.handlerOpenNewTab} id="results" data-content="students_result">Результаты учащихся</button>
              </li>
              <li>
                <button onClick={this.handlerOpenNewTab} id="createTest" className="active" data-content="create_test">Создать тест</button>
              </li>
              <li>
                <button onClick={this.handlerOpenNewTab} id="myTests" data-content="my_test">Мои тесты</button>
              </li>
            </ul>
              <div className="test_content active" id="create_test">
                <div className="test_name">
                  <label htmlFor="test_title">Название теста:</label>
                  <input onChange={this.handleChangeTestTitle} type="text" placeholder="Введите название теста" value={newTestTitle ? newTestTitle : test.title}/>
                </div>
                <div className="max_questions">
                  <label htmlFor="max_questions_title">Максимально вопросов в тесте:</label>
                  <input onChange={this.handleChangeMaxQuestion} type="number" value={newTestMaxQuestions? newTestMaxQuestions : test.maxQuestions}/>
                </div>
                <div className="max_questions">
                  <label htmlFor="max_questions_class">Классы: </label>
                  <input onChange={this.handleChangeForClass} type="number" value={newTestClass ? newTestClass : test.forClass}/>
                </div>
                <div className="save_questions">
                  <div className="save_questions_left" id="save_questions_left">
                    {questions.map((item, index) => (
                      <QuestionItem
                        questionTitle={item.title}
                        questionNumber={item.id}
                        key={index}
                        editPage={true}
                        questionSelected={selectedQuestion ? selectedQuestion.id : undefined}
                        handleSelectQuestion={this.handleSelectQuestion}
                        questionLevel={item.level}
                        handleRemoveButton={this.handleRemoveQuestionButton}
                      />
                    ))}
                  </div>
                  {selectedQuestion && (
                    <CreateQuestionForm
                      editPage={true}
                      question={selectedQuestion}
                      handleSaveQuestion={this.handleSaveQuestion}
                    />
                  )}
                </div>
                <button onClick={this.handleClickSaveTestButton} className="save_btn btn_save">СОХРАНИТЬ ТЕСТ</button>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

import React, {Component} from 'react';

import logo from '../../resources/logo.png';
import {clearLocalStorage} from "../../utils/localStorage";

export default class AppFooterLayout extends Component {
  handleLogout()
  {
    clearLocalStorage();
    window.location.replace('/');
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer">
          <div className="container">
            <div className="footer_main">
              <div className="logo_footer">
                <img src={logo} alt="logo" />
                <p>Сервис онлайн-тестирования в сфере образования</p>
              </div>
              <div className="privacy">
                Политика конфиденциальности
              </div>
              <div><button onClick={this.handleLogout}>Выйти</button></div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

import React, { Component } from 'react';
import { backendURL } from "../../utils/env";
import {saveLoginData, userIsAuth, userIsTeacher} from "../../utils/localStorage";
import {validateFio} from "../../utils/validator";

export default class AuthRegPage extends Component {
  constructor() {
    super();

    this.state = {
      authTab: false,
      imTeacher: false,
      regTeacherFio: undefined,
      regTeacherLogin: undefined,
      regTeacherLesson: undefined,
      regTeacherPassword: undefined,
      regStudentFio: undefined,
      regStudentLogin: undefined,
      regStudentPassword: undefined,
      regStudentClassNumber: 7,
      regStudentClassChar: 'b',
      authLogin: undefined,
      authPass: undefined,
    };

    this.handleSelectTab = this.handleSelectTab.bind(this);
    this.handleSelectTabWho = this.handleSelectTabWho.bind(this);
    this.handleRegTeacher = this.handleRegTeacher.bind(this);
    this.handleRegTeacherButton = this.handleRegTeacherButton.bind(this);
    this.handleRegStudent = this.handleRegStudent.bind(this);
    this.handleRegStudentButton = this.handleRegStudentButton.bind(this);
    this.handleAuthFields = this.handleAuthFields.bind(this);
    this.handleAuthButton = this.handleAuthButton.bind(this);
  };

  handleSelectTab(e) {
    const id = e.target.id;
    if (id === 'auth') this.setState({authTab: true})
    else if (id === 'reg') this.setState({authTab: false})
  };

  handleSelectTabWho(e) {
    const id = e.target.id;
    if (id === 'teacher') this.setState({imTeacher: true})
    else if (id === 'student') this.setState({imTeacher: false})
  }

  handleRegTeacherButton(e) {
    e.preventDefault();
    if (this.state.regTeacherFio && validateFio(this.state.regTeacherFio)
      && this.state.regTeacherLogin
      && this.state.regTeacherLesson
      && this.state.regTeacherPassword) {
      const formdata = new FormData();
      formdata.append("login", this.state.regTeacherLogin);
      formdata.append("password", this.state.regTeacherPassword);
      formdata.append("teacher", 'true');
      formdata.append("fio", this.state.regTeacherFio);
      formdata.append("lesson", this.state.regTeacherLesson);

      fetch(`${backendURL}/api/user`, {
        method: 'POST',
        body: formdata
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.meta.error === null) {
              saveLoginData(result.response);
              return window.location.replace("/teacher/tests-results");
            } else alert(result.meta.error);
          },
          (error) => {
            console.log(error);
          },
        );
    } else alert('Поля регистрации пустые!');
  }

  handleRegStudentButton(e) {
    e.preventDefault();
    if (this.state.regStudentFio && validateFio(this.state.regStudentFio)
    && this.state.regStudentLogin
    && this.state.regStudentClassNumber
    && this.state.regStudentClassChar
    && this.state.regStudentPassword) {
      const formdata = new FormData();
      formdata.append("login", this.state.regStudentLogin);
      formdata.append("password", this.state.regStudentPassword);
      formdata.append("teacher", 'false');
      formdata.append("classNumber", this.state.regStudentClassNumber);
      formdata.append("classChar", this.state.regStudentClassChar);
      formdata.append("fio", this.state.regStudentFio);

      fetch(`${backendURL}/api/user`, {
        method: 'POST',
        body: formdata
      })
        .then((res) => res.json())
        .then(
          (result) => {
            console.log('test1');
            console.log(result);
            if (result.meta.error === null) {
              saveLoginData(result.response);
              window.location.replace("/student/available-tests");
            } else alert(result.meta.error);
          },
          (error) => {
            console.log(error);
          },
        );
    } else alert('Поля регистрации пустые!');
  }

  handleAuthButton(e) {
    e.preventDefault();
    if (this.state.authLogin && this.state.authPass) {
      const formdata = new FormData();
      formdata.append("login", this.state.authLogin);
      formdata.append("password", this.state.authPass);

      fetch(`${backendURL}/api/user/login`, {
        method: 'POST',
        body: formdata
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.meta.error === null) {
              saveLoginData(result.response);
              if (result.response.teacher) window.location.replace("/teacher/tests-results");
              else window.location.replace("/student/available-tests");

            } else alert(result.meta.error);
          },
          (error) => {
            console.log(error);
          },
        );
    } else alert('Поля авторизации пустые!');
  }

  handleRegTeacher(e) {
    if (e.target.id === 'fio_teacher') this.setState({regTeacherFio: e.target.value});
    else if (e.target.id === 'lesson') this.setState({regTeacherLesson: e.target.value});
    else if (e.target.id === 'login_teacher') this.setState({regTeacherLogin: e.target.value});
    else if (e.target.id === 'password_teacher') this.setState({regTeacherPassword: e.target.value});
  }

  handleRegStudent(e) {
    if (e.target.id === 'fio_student') this.setState({regStudentFio: e.target.value});
    else if (e.target.id === 'class_student_number') this.setState({regStudentClassNumber: e.target.value});
    else if (e.target.id === 'class_student_letters') this.setState({regStudentClassChar: e.target.value});
    else if (e.target.id === 'login_student') this.setState({regStudentLogin: e.target.value});
    else if (e.target.id === 'password_student') this.setState({regStudentPassword: e.target.value});
  }

  handleAuthFields(e) {
    if (e.target.id === 'login_auth') this.setState({authLogin: e.target.value});
    else if (e.target.id === 'pass_auth') this.setState({authPass: e.target.value});
  }

  componentDidMount() {
    if (userIsAuth) {
      if (userIsTeacher) window.location.replace('/teacher/create-test');
      else window.location.replace('/student/available-tests');
    }
  }

  render() {
    const {
      authTab, imTeacher, regStudentClassNumber, regStudentClassChar,
    } = this.state;
    return (
      <div className="container">
        <div className="session_tabs">
          <ul className="nav_tabs">
            <li>
              <button className={`reg ${!authTab && 'active'}`} id='reg' onClick={this.handleSelectTab}>РЕГИСТРАЦИЯ</button>
            </li>
            <li>
              <button className={`login ${authTab && 'active'}`} id='auth' onClick={this.handleSelectTab}>ВОЙТИ</button>
            </li>
          </ul>
          <div className="tab_body">
            <div className={`content ${!authTab && 'active'}`} id="reg">
              <div className="who_are">
                <div className="who_are_head">
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="who"
                        value="student"
                        checked={!imTeacher && true}
                        data-content="student"
                        id='student'
                        onChange={this.handleSelectTabWho}
                      />
                        Я ученик
                    </label>
                  </div>
                  <div>
                    <label>
                      <input
                        type="radio"
                        name="who"
                        value="teacher"
                        data-content="teacher"
                        id='teacher'
                        checked={imTeacher && true}
                        onChange={this.handleSelectTabWho}
                      />
                        Я преподаватель
                    </label>
                  </div>
                </div>
                <div className={`content_one ${!imTeacher && 'active'}`} id="student">
                  <form>
                    <input type="text" id="fio_student" placeholder="Фамилия Имя Очество" onChange={this.handleRegStudent} required />
                      <div className="class_selection">
                        <label htmlFor="class_student">Выберите свой класс из списка:</label>
                        <select id="class_student_number" onChange={this.handleRegStudent} value={regStudentClassNumber}>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                          <option value="11">11</option>
                        </select>
                        <select id="class_student_letters" onChange={this.handleRegStudent} value={regStudentClassChar}>
                          <option value="a">A</option>
                          <option value="b">Б</option>
                          <option value="v">В</option>
                          <option value="g">Г</option>
                          <option value="d">Д</option>
                        </select>
                      </div>
                    <input type="text" id="login_student" placeholder="Email (логин)" onChange={this.handleRegStudent} required />
                    <input type="password" id="password_student" placeholder="Придумайте пароль" onChange={this.handleRegStudent} required />
                    <button onClick={this.handleRegStudentButton}>ЗАРЕГИСТРИРОВАТЬСЯ</button>
                  </form>
                </div>
                <div className={`content_one ${imTeacher && 'active'}`} id="teacher">
                  <form>
                    <input type="text" id='fio_teacher' placeholder="Фамилия Имя Очество" onChange={this.handleRegTeacher} required />
                    <input type="text" id="lesson" placeholder="Преподаваемый предмет"  onChange={this.handleRegTeacher} />
                    <input type="email" id="login_teacher" placeholder="Email (логин)"  onChange={this.handleRegTeacher} required />
                    <input type="password" id="password_teacher" placeholder="Придумайте пароль "  onChange={this.handleRegTeacher} required />
                    <button onClick={this.handleRegTeacherButton}>ЗАРЕГИСТРИРОВАТЬСЯ</button>
                  </form>
                </div>
              </div>
            </div>
            <div className={`content ${authTab && 'active'}`} id="login">
              <form>
                <input type="text" id="login_auth" placeholder="Email (логин)" onChange={this.handleAuthFields} required />
                <input type="password" id="pass_auth" placeholder="Пароль" onChange={this.handleAuthFields} required />
                <button onClick={this.handleAuthButton}>ВОЙТИ</button>
                    {/*<div className="forgot">*/}
                    {/*  <a href="#">Забыли пароль?</a>*/}
                    {/*</div>*/}
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  };
}

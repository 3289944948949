import React from 'react';
import ReactDOM from 'react-dom';
import {
  BrowserRouter, Switch, Route,
} from 'react-router-dom';
import { AppLayout } from './layouts';
import reportWebVitals from './reportWebVitals';
import {
  AuthPage,
  StudentAvailableTestsPage,
  TakeTestPage,
  PassedTestsPage,
  CreateTestPage,
  StudentResultsPage,
  MyTestsPage,
  ViewResultPage,
  ViewPage,
  ViewResultByTeacher,
  EditTestPage,
} from "./pages";

ReactDOM.render(
  <BrowserRouter>
    <AppLayout>
      <Switch>
        <Route exact path="/" component={AuthPage} />
        <Route exact path="/teacher/create-test" component={CreateTestPage} />
        <Route exact path="/teacher/tests-results" component={StudentResultsPage} />
        <Route exact path="/teacher/my-tests" component={MyTestsPage} />
        <Route exact path="/teacher/my-tests/view/:testID" component={ViewPage} />
        <Route exact path="/teacher/my-tests/edit/:testID" component={EditTestPage} />
        <Route exact path="/teacher/view-results/:resultID" component={ViewResultByTeacher} />
        <Route exact path="/student/available-tests" component={StudentAvailableTestsPage} />
        <Route exact path="/student/passed-tests" component={PassedTestsPage} />
        <Route exact path="/student/take-test/:testID" component={TakeTestPage} />
        <Route exact path="/student/view-result/:resultID" component={ViewResultPage} />
      </Switch>
    </AppLayout>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { Component } from 'react';

import QuestionItem from "../../components/QuestionItem";
import CreateQuestionForm from "../../forms/CreateQuestion";
import {clearLocalStorage, userID, userIsAuth, userIsTeacher} from "../../utils/localStorage";
import {backendURL} from "../../utils/env";

export default class CreateTest extends Component {
  constructor() {
    super();

    this.state = {
      testTitle: undefined,
      maxQuestions: undefined,
      forClass: undefined,
      questions: [],
    };

    this.handlerOpenNewTab = this.handlerOpenNewTab.bind(this);
    this.handleChangeTestTitle = this.handleChangeTestTitle.bind(this);
    this.handleMaxQuestions = this.handleMaxQuestions.bind(this);
    this.handleChangeClassNumber = this.handleChangeClassNumber.bind(this);
    this.handleSaveQuestion = this.handleSaveQuestion.bind(this);
    this.handleRemoveQuestion = this.handleRemoveQuestion.bind(this);
    this.handleCLickSaveTestButton = this.handleCLickSaveTestButton.bind(this);
  }

  handlerOpenNewTab(e)
  {
    if (e.target.id === 'results') window.location.replace('/teacher/tests-results');
    else if (e.target.id === 'myTests') window.location.replace('/teacher/my-tests');
    else if (e.target.id === 'createTest') window.location.replace('/teacher/create-test');
  }

  handleChangeTestTitle(e) {
    this.setState({testTitle: e.target.value});
  }

  handleMaxQuestions(e) {
    this.setState({maxQuestions: e.target.value});
  }

  handleChangeClassNumber(e) {
    this.setState({forClass: e.target.value});
  }

  handleSaveQuestion(e) {
    this.setState(prevState => ({
      questions: [...prevState.questions, {
        questionTitle: e.questionTitle,
        questionLevel: e.questionLevel,
        answers: e.answers,
      }]
    }))
  }

  handleRemoveQuestion(e) {
    const newArr = [...this.state.questions];
    newArr.splice(e.id, 1);
    this.setState({questions: newArr});
  }

  async handleCLickSaveTestButton(e) {
    e.preventDefault();
    if (this.state.testTitle !== undefined
      && this.state.maxQuestions !== undefined
      && this.state.forClass !== undefined
      && this.state.questions.length >= this.state.maxQuestions && this.state.questions.length >= 40
    ) {
      let createdTestID = undefined;
      const formdata = new FormData();
      formdata.append("owner", userID);
      formdata.append("title", this.state.testTitle);
      formdata.append("forClass", this.state.forClass);
      formdata.append("maxQuestions", this.state.maxQuestions);

      await fetch(`${backendURL}/api/test`, {
        method: 'POST',
        body: formdata
      })
        .then((res) => res.json())
        .then(
          (result) => {
            if (result.meta.error === null) {
              createdTestID = result.response.id;
            } else alert(result.meta.error);
          },
          (error) => {
            console.log(error);
          },
        );

      if (createdTestID) {
        this.state.questions.map(async (question) => {
          let createdQuestionID = undefined;

          const formdata = new FormData();
          formdata.append("title", question.questionTitle);
          formdata.append("level", question.questionLevel);
          formdata.append("forTest", createdTestID);

          await fetch(`${backendURL}/api/question`, {
            method: 'POST',
            body: formdata
          })
            .then((res) => res.json())
            .then(
              (result) => {
                if (result.meta.error === null) {
                  createdQuestionID = result.response.id;
                } else console.log(result.meta.error);
              },
              (error) => {
                console.log(error);
              },
            );

          if (createdQuestionID) {
            question.answers.map(async (answer) => {
              const formdata = new FormData();
              formdata.append("title", answer.title);
              formdata.append("right", answer.rightAnswer);
              formdata.append("forQuestion", createdQuestionID);

              await fetch(`${backendURL}/api/answer-to-question`, {
                method: 'POST',
                body: formdata
              })
                .then((res) => res.json())
                .then(
                  (result) => {
                    if (result.meta.error !== null) {
                      console.log(result.meta.error);
                    }
                  },
                  (error) => {
                    console.log(error);
                  },
                );
            })
          }
        });
        alert("Тест успешно создан! Сейчас откроется страница с созданными тестами");
        setTimeout(() => {
          window.location.replace('/teacher/my-tests');
        }, 10000);
      }
    } else alert("Одно или несколько полей не заполненны корректно");
  }

  componentDidMount() {
    if (!userIsAuth) {
      clearLocalStorage();
      window.location.replace('/');
    } else {
      if (!userIsTeacher) window.location.replace('/student/available-tests');
    }
  }

  render() {
    const {
      questions,
    } = this.state;

    return (
      <div className="mb">
        <div className="container ">
          <div className="test_tab teacher_tab">
            <ul className="test_tab_nav">
              <li>
                <button onClick={this.handlerOpenNewTab} id="results" data-content="students_result">Результаты учащихся</button>
              </li>
              <li>
                <button onClick={this.handlerOpenNewTab} id="createTest" className="active" data-content="create_test">Создать тест</button>
              </li>
              <li>
                <button onClick={this.handlerOpenNewTab} id="myTests" data-content="my_test">Мои тесты</button>
              </li>
            </ul>
              <div className="test_content active" id="create_test">
                <form action="">
                  <div className="test_name">
                    <label htmlFor="test_title">Название теста:</label>
                    <input onChange={this.handleChangeTestTitle} type="text" name="test_title" id="test_title" placeholder="Введите название теста" />
                  </div>
                  <div className="max_questions">
                    <label htmlFor="max_questions_title">Максимально вопросов в тесте:</label>
                    <input onChange={this.handleMaxQuestions} type="number" name="max_questions_title" id="max_questions_title" />
                  </div>
                  <div className="max_questions">
                    <label htmlFor="max_questions_class">Классы: </label>
                    <input onChange={this.handleChangeClassNumber} type="number" name="max_questions_class" id="max_questions_class" />
                  </div>
                  <div className="save_questions">
                    <div className="save_questions_left" id="save_questions_left">
                      {questions.map((item, index) => (
                        <QuestionItem
                          questionTitle={item.questionTitle}
                          questionNumber={index}
                          handleRemoveButton={this.handleRemoveQuestion}
                          key={index}
                        />
                      ))}
                    </div>
                    <CreateQuestionForm
                      handleSaveQuestion={this.handleSaveQuestion}
                    />
                  </div>

                  <button onClick={this.handleCLickSaveTestButton} className="save_btn btn_save">СОХРАНИТЬ ТЕСТ</button>
                  <button onClick={this.handleCLickSaveTestButton} className="save_btn btn_save_mobile">СОХРАНИТЬ ТЕСТ</button>
                </form>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

import React from 'react';

import {userID} from "./localStorage";
import {backendURL} from "./env";

/**
 * Save result
 *
 * @param {!number} test
 * @param {!number} score
 */
export const saveResult = async (test, score) => {
  let createdResult = undefined;
  const formdata = new FormData();
  formdata.append("owner", userID);
  formdata.append("test", test);
  formdata.append("score", score);

  await fetch(`${backendURL}/api/results`, {
    method: 'POST',
    body: formdata
  })
    .then((res) => res.json())
    .then(
      (result) => {
        createdResult = result.response.id;
      },
      (error) => {
        console.log(error);
      },
    );
  return createdResult;
}

/**
 * Save answer to question
 *
 * @param {!number} result
 * @param {!number} question
 * @param {!number} answer
 *
 * @return {Object} response
 */
export const saveAnsweredResult = async (result, question, answer) => {
  const formdata = new FormData();
  formdata.append("result", result);
  formdata.append("question", question);
  formdata.append("answer", answer);

  fetch(`${backendURL}/api/answered-results`, {
    method: 'POST',
    body: formdata
  })
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        console.log(error);
      },
    );
}
